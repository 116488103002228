import React, { Component, ReactElement, Suspense } from 'react';
import { ThemeProvider } from '@creditas/stylitas';
import tokens from '@creditas/tokens';
import { Global } from '@creditas/global';
import { AppProps } from 'single-spa';
import { AppCustomProps, defaultCustomProps } from '.';
import { CustomPropsProvider } from './contexts';
import { Routes } from './Routes';

/* eslint-disable react/prefer-stateless-function */
export class App extends Component<AppCustomProps & AppProps> {
  render(): ReactElement {
    return (
      <CustomPropsProvider value={{ ...defaultCustomProps, ...this.props }}>
        <Suspense fallback={null}>
          <ThemeProvider theme={{ ...tokens }}>
            <Global />
            <Routes />
          </ThemeProvider>
        </Suspense>
      </CustomPropsProvider>
    );
  }
}
