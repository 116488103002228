import { css, styled } from '@creditas/stylitas';

export const SearchFormStyled = styled.div`
  margin: 40px 48px 0;
  position: relative;
  padding-top: 20px;

  input {
    font-size: 24px;
  }

  svg {
    display: none;
  }
`;

export const searchButtonStyled = () => css`
  float: right;
  position: absolute;
  top: 0;
  right: 0;
  min-width: 170px;
`;
