import singleSpaReact from 'single-spa-react';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import { App } from './App';
import { i18nInit } from './i18n';
import { envVars } from './envVars';

const {
  APP_NAME,
  REACT_APP_ENV,
  REACT_APP_DATADOG_APPLICATION_ID,
  REACT_APP_DATADOG_CLIENT_TOKEN,
  REACT_APP_DATADOG_SERVICE,
  REACT_APP_DATADOG_VERSION,
} = envVars;

const reactLifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: App,
  domElementGetter: (): HTMLElement =>
    document.getElementById(APP_NAME) || document.createElement('div'),
  errorBoundary: error => (
    <h1>
      Oraculo:
      {JSON.stringify(error)}
    </h1>
  ),
});

export const bootstrap = [
  reactLifecycles.bootstrap,
  async (): Promise<void> => {
    datadogLogs.init({
      clientToken: REACT_APP_DATADOG_CLIENT_TOKEN,
      version: REACT_APP_DATADOG_VERSION,
      service: REACT_APP_DATADOG_SERVICE,
      site: 'datadoghq.com',
      forwardErrorsToLogs: true,
      sampleRate: 100,
    });

    datadogRum.init({
      applicationId: REACT_APP_DATADOG_APPLICATION_ID,
      clientToken: REACT_APP_DATADOG_CLIENT_TOKEN,
      service: REACT_APP_DATADOG_SERVICE,
      env: REACT_APP_ENV,
      version: REACT_APP_DATADOG_VERSION,
      site: 'datadoghq.com',
      sampleRate: 100,
      premiumSampleRate: 100,
      trackInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
    });

    datadogRum.startSessionReplayRecording();

    await i18nInit;
    return Promise.resolve();
  },
];

export const mount = [reactLifecycles.mount];

export const unmount = [reactLifecycles.unmount];
