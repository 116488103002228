import React, { useEffect, useState } from 'react';
import { useTranslation } from '@creditas/i18n';
import { Button } from '@creditas/button';
import { TextField } from '@creditas/input';
import { styled } from '@creditas/stylitas';
import { searchButtonStyled, SearchFormStyled } from './SearchForm.style';
import { envVars } from '../envVars';

const SearchButton = styled(Button)`
  ${searchButtonStyled};
`;

type Error = {
  status: boolean;
  feedbackText: string;
};

const SearchForm = (): React.ReactElement => {
  const { t } = useTranslation();
  const input = React.useRef<HTMLInputElement>(null);
  const [error, setError] = useState<Error>({ status: false, feedbackText: '' });
  const [loading, setLoading] = useState<boolean>(false);

  const getInputValue = (): string => input.current?.value.trim() ?? '';

  const isValidInputSearch = (): boolean => getInputValue().length >= 3;

  const isValidCpf = (): boolean =>
    new RegExp('^[0-9]+$').test(getInputValue().replace(/\D+/g, '')) &&
    getInputValue().replace(/\D+/g, '').length === 11;

  const handleChange = (): void => {
    setError(
      isValidInputSearch()
        ? { status: false, feedbackText: '' }
        : { status: true, feedbackText: t('invalidSearchValue') },
    );
  };

  const handleClick = (): void => {
    if (isValidInputSearch()) {
      window.location.href = `${envVars.GREENCARE_URL}${t(
        isValidCpf() ? 'routes.newClientProfile' : 'routes.clientProfile',
      ).replace(':key', getInputValue())}`;
      setLoading(true);
    }
  };

  const handleKeyDown = (e: KeyboardEvent): void => {
    if (e.key === 'Enter') {
      handleClick();
    }
  };

  useEffect(() => {
    input.current?.focus();
  }, []);

  const textFieldProps = {
    type: 'text',
    inputRef: input,
    placeholder: t('searchBy'),
    error: error.status,
    feedbackText: error.feedbackText,
    onChange: handleChange,
    InputProps: {
      onKeyDown: handleKeyDown,
    },
  };

  return (
    <SearchFormStyled>
      <TextField {...textFieldProps} />
      <SearchButton onClick={handleClick} disabled={error.status} isLoading={loading}>
        {t('search')}
      </SearchButton>
    </SearchFormStyled>
  );
};

export { SearchForm };
