import { i18n, initReactI18next } from '@creditas/i18n';

import ptBr from './translations/pt-BR.json';

const resources = {
  'pt-BR': { oraculo: ptBr },
};

export const i18nInit = i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    ns: ['oraculo'],
    defaultNS: 'oraculo',
    fallbackLng: 'pt-BR',
    lng: 'pt-BR',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });
