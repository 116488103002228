import React, { useContext } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { useTranslation } from '@creditas/i18n';
import { CustomPropsContext } from './contexts/CustomPropsContext';
import { SearchForm } from './SearchForm';

export const Routes = (): React.ReactElement => {
  const { t } = useTranslation();
  const { basePath } = useContext(CustomPropsContext);

  return (
    <BrowserRouter>
      <Switch>
        <Route path={`${basePath}/${t('routes.search')}`} component={SearchForm} />
      </Switch>
    </BrowserRouter>
  );
};
